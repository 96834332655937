<template>
<div>
    <page-header :title="`Thêm mới phân loại khảo sát`" :items="breadcrumbs" />
    <div v-if="!loading">
        <b-row>
            <b-col lg="12">
                <b-card>
                    <b-form-group>
                        <label>Loại khảo sát <span class="red">*</span></label>
                        <b-input v-model="form.name" placeholder="Nhập loại khảo sát" />
                    </b-form-group>
                    <b-form-group>
                        <label>Thứ tự</label>
                        <b-input type="number" v-model.number="form.order" min="1" max="50" placeholder="Nhập thứ tự"/>
                    </b-form-group>
                    <b-form-group>
                        <div v-if="showAddErrorMessage" class="alert alert-danger" role="alert">
                            {{addErrorMessage}}
                        </div>
                        <b-button variant="success" class="mr-2" @click.prevent="create">
                            Lưu
                        </b-button>
                        <b-button variant="danger" @click.prevent="toListView">
                            Huỷ
                        </b-button>
                    </b-form-group>
                </b-card>
            </b-col>
        </b-row>
    </div>
</div>
</template>

<script>
import PageHeader from '../../../components/page-header';
import {
    httpClient
} from "../../../_utils/httpClient";
import Swal from 'sweetalert2';

export default {
    name: 'create',
    components: {
        PageHeader
    },
    data: function () {
        return {
            breadcrumbs: [{
                text: 'Trang chủ',
                to: '/admin/home',
            }, {
                text: 'Thiết lập phân loại khảo sát',
                to: '/admin/config-plks',
            }, {
                text: 'Thêm mới',
                to: '#',
                active: true,
            }, ],
            showAddErrorMessage: false,
            addErrorMessage: '',
            form: {
                config: 'phanloaiquyetdinh',
                name: '',
                order: null
            },
            loading: false,
        };
    },
    methods: {
        async toListView() {
            await this.$router.push({
                name: 'admin.config.plks'
            })
        },
        async getData() {
            let response = await httpClient.get(`config`, {
                params: {
                    name: 'phanloaiquyetdinh'
                }
            })
            if (response.status === 200 && response.data.code === 0) {
                this.entries = response.data.data
            }
        },
        validator() {            
            if (this.form.name == null || this.form.name == "") {
                this.showAddErrorMessage = true
                this.addErrorMessage = `Loại khảo sát không được trống.`
                return false
            }            
            return true
        },
        async create() {
            this.showAddErrorMessage = false
            if (!this.validator()) return
            if(!this.form.order) this.form.order = 0;
            let payload = {
                ...this.form
            }
            await Swal.queue([{
                title: 'Thêm mới phân loại loại khảo sát này?',
                confirmButtonText: 'xác nhận',
                confirmButtonColor: '#34c38f',
                showCancelButton: true,
                cancelButtonText: 'Hủy bỏ',
                cancelButtonColor: '#f46a6a',
                showLoaderOnConfirm: true,
                preConfirm: async () => {
                    let response = await httpClient.post(`config`, payload)
                    if (response.data.code === 0) {
                        Swal.insertQueueStep({
                            title: 'Thêm mới phân loại loại khảo sát thành công',
                        });
                        await this.toListView()
                    } else {
                        Swal.insertQueueStep({
                            title: 'Thêm mới phân loại loại khảo sát không thành công',
                        });
                        this.showAddErrorMessage = true
                        this.addErrorMessage = response.data
                    }
                    return true;
                },
            },]);
        },
    },
    created() {
        this.getData();
    },
};
</script>

<style lang="scss" scoped>
.action-column {
    width: 120px;
    text-align: center;
}
</style>
<style>
.red {
    color: red;
}
</style>